import React from 'react';
import Wordmark from '../assets/logos/wordmark.svg';
import Burger from './burger';
import '../styles/nav.scss';

const Nav = ({ children, background }) => {

    const menuOptions = {
        'case studies': '/blog',
        'blog': '/blog',
        'careers': '/careers',
        'contact': '/#contact'
    }

    return (
        <nav className={"nav " + background}>
            <a href="/" className="logo">
                <Wordmark />
            </a>
            <div className="link-list">
                {Object.keys(menuOptions).map(option => {
                    return (
                        <a href={menuOptions[option]} className="ff">{option}</a>
                    )
                })}
            </div>
            <Burger menuOptions={menuOptions} />
        </nav>
    )

}

export default Nav