import React, { useState, useEffect } from 'react';
import Seo from '../components/seo.js';
import Nav from '../components/nav.js';
import Footer from '../components/footer.js';
import '../styles/global.scss';

const Layout = ({ children, background }) => {
    const [cursorPos, setCursorPos] = useState(null);
    const [cursorSize, setCursorSize] = useState('default');
    
    const handleMouseMove = (e) => {
        setCursorPos({ x: e.clientX, y: e.clientY });
    }

    const handleMouseEnter = () => {
        setCursorSize('hover');
    }

    const handleMouseLeave = () => {
        setCursorSize('default');
    }

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        document.querySelectorAll("a, button, video, .video-container, input, textarea, label").forEach((element) => {
            element.addEventListener('mouseenter', handleMouseEnter);
            element.addEventListener('mouseleave', handleMouseLeave);
        });

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseenter', handleMouseEnter);
            window.removeEventListener('mouseleave', handleMouseLeave);
        }
    }, []);
    
    return (
        <div className='site-wrapper'>
            <Seo />
            <Nav background={background} />
            { children }
            <Footer />
            <div className={`cursor ${cursorSize}`} style={{ left: cursorPos?.x, top: cursorPos?.y }}></div>
        </div>
    )

}

export default Layout